import { callNumberOfTries } from '../utils/callNumberOfTries';

const MAX_NUMBER_OF_TRIES = 3;

export const activitiesService = {
  loadActivities: async (studentId, activityType, schoolId) =>
    await callNumberOfTries(
      window.cet.microservices.studentsapi.activities.getActivities,
      {
        studentId,
        activityType,
        schoolId
      },
      MAX_NUMBER_OF_TRIES
    ),
  removeBookFromMyBooks: async (studentId, schoolId, externalId) => window.cet.microservices.studentsapi.activities.hideActivity({ studentId, schoolId, externalId })
};
