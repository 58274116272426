import { useIntl } from 'react-intl';
import todo from './../pages/Tasks/Lottie/todo.json';
import returned from './../pages/Tasks/Lottie/returned.json';
import evaluated from './../pages/Tasks/Lottie/evaluated.json';
import submitted from './../pages/Tasks/Lottie/submitted.json';
import todo_mobile from './../pages/Tasks/Lottie/todo_mobile.json';
import returned_mobile from './../pages/Tasks/Lottie/returned_mobile.json';
import evaluated_mobile from './../pages/Tasks/Lottie/evaluated_mobile.json';
import submitted_mobile from './../pages/Tasks/Lottie/submitted_mobile.json';

export const useTasksConfig = tasks => {
  const intl = useIntl();
  const filters = [
    {
      value: 'all',
      label: intl.formatMessage({ id: 'all' }),
      totalCount: tasks.todo?.all.length ?? 0,
    },
    {
      value: 'urgent',
      label: intl.formatMessage({ id: 'most_urgent_activities' }),
      totalCount: tasks.todo?.urgent.length ?? 0,
    },
    {
      value: 'late',
      label: intl.formatMessage({ id: 'late_activities' }),
      totalCount: tasks.todo?.late.length ?? 0,
    }
  ];

  const tabs = [
    {
      value: 'todo',
      label: intl.formatMessage({ id: 'tasks_todo' }),
      totalCount: tasks.todo?.all.length ?? 0,
      lottie: todo,
      lottieMobile: todo_mobile,
      primaryColor: '#34BBE0',
      secondaryColor: '#b9e0f1',
      hasFilter: true
    },
    {
      value: 'returned',
      label: intl.formatMessage({ id: 'tasks_returned' }),
      totalCount: tasks.returned?.all.length ?? 0,
      lottie: returned,
      lottieMobile: returned_mobile,
      primaryColor: '#FFA048',
      secondaryColor: '#ffd9b6',
      hasFilter: false
    },
    {
      value: 'evaluated',
      label: intl.formatMessage({ id: 'tasks_evaluated' }),
      totalCount: tasks.evaluated?.all.length ?? 0,
      lottie: evaluated,
      lottieMobile: evaluated_mobile,
      primaryColor: '#3AD38F',
      secondaryColor: '#c0ebd0',
      hasFilter: false
    },
    {
      value: 'submitted',
      label: intl.formatMessage({ id: 'tasks_submitted' }),
      totalCount: tasks.submitted?.all.length ?? 0,
      lottie: submitted,
      lottieMobile: submitted_mobile,
      primaryColor: '#FC79C7',
      secondaryColor: '#fbc4e6',
      hasFilter: false
    }
  ];
  return [filters, tabs];
};
