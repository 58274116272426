export const callNumberOfTries = (functionToCall, params, maxNumberOfTries = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await functionToCall(params);
      resolve(result);
    } catch (ex) {
      if (maxNumberOfTries > 0) {
        callNumberOfTries(functionToCall, params, maxNumberOfTries - 1);
      } else {
        reject(ex);
      }
    }
  });
};