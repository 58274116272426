import { getStudyYearDataByDate } from '../legacyComponents/utils/helpers';
import { callWithCache } from '../utils/callWithCache';

export const configurationsService = {
  loadSchoolYearRange: async () => {
    return new Promise(resolve => {
      const dates = getStudyYearDataByDate(new Date());
      resolve({
        start: dates.start.toString(),
        end: dates.end.toString()
      });
    });
  },

  loadLabels: async (systemLanguages = ['he', 'ar']) => {
    const languages = systemLanguages.join(',');
    return await callWithCache(window.cet.microservices.nairobiapi.label.getLabels, 'getLabels', { languages }, 3);

  },

  loadAllDisciplines: async lang => callWithCache(window.cet.microservices.contentapi.content.getDimension, 'allDisciplines', { lang, dimension: 'disciplines' }),

  loadAllDimensions: async lang => callWithCache(window.cet.microservices.contentapi.content.getAllDimensionsByLanguage, 'allDimensions', { lang }),

  loadAllDependencies: async lang => callWithCache(window.cet.microservices.contentapi.content.getAllDependencies, 'allDependencies')
};
