export const dataStructure = {
  convertSearchRecordsToArray: (records, disciplinesMap) => {
    return Object.keys(records)
      .reduce((data, discipline) => {
        return [...data, { discipline, records: records[discipline], orderBy: disciplinesMap[discipline]?.orderBy ?? 9999 }];
      }, [])
      .sort((book1, book2) => (book1.orderBy > book2.orderBy ? 1 : -1));
  },

  convertArrayToObject: (array, key) => {
    return array.reduce((result, item) => {
      if (!result[item[key]]) {
        result[item[key]] = item;
      }

      return result;
    }, {});
  }
};
