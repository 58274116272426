import { useEffect, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useCetEvents } from 'cet-components-lib/dist/hooks';

export const usePagesLearningEvents = () => {
  const intl = useIntl();
  const { sendLearningEvent } = useCetEvents('myOfek', intl.locale);
  const { pathname } = useLocation();
  
  const isLaunchReady = useRef(false);
  const isReloadSent = useRef(false);

  const getPageName = useCallback(() => {
    if (pathname) {
      const pageName = pathname.split(`/${intl.locale}/`)[1];
      return pageName && pageName !== '' ? pageName : 'tasks';
    }
  }, [intl.locale, pathname]);

  useEffect(() => {
    if (!isReloadSent.current) {
      let isReload = window.performance?.getEntriesByType && window.performance.getEntriesByType('navigation');
      isReload = isReload?.length > 0 && isReload[0].type && isReload[0].type === 'reload';

      if (isReload) {
        if (!window.contextReferrer) {
          //page refreshed
          window.contextReferrer = window.document.referrer || window.location.href;
        }
        window.currentHyperlink = window.location.href;
        sendLearningEvent({
          verbType: 'exited',
          objectName: getPageName(),
          objectId: getPageName(),
        });
      }
    }
    isReloadSent.current = true;
  }, [getPageName, pathname, sendLearningEvent]); //run once on init (checks for previously refreshed)

  useEffect(() => {
    if (isLaunchReady.current) {
       if (!window.contextReferrer) {
         window.contextReferrer = window.document.referrer || window.location.href;
       }
       window.currentHyperlink = window.location.href;
       sendLearningEvent({
         verbType: 'launched',
         objectName: getPageName(),
         objectId: getPageName(),
       });
      
      return () => {
        sendLearningEvent({
          verbType: 'exited',
          objectName: getPageName(),
          objectId: getPageName(),
        });
        window.contextReferrer = window.currentHyperlink;
      };
    }
    isLaunchReady.current = true;
  }, [getPageName, pathname, sendLearningEvent]);
};
