export const outsidePromise = () => {
    let resolver;
    let rejecter;
    
    const promise = new Promise((resolve, reject) => {
        resolver = resolve;
        rejecter = reject;
    });

    return {
        promise,
        resolver,
        rejecter
    };
};

export const getRandomInt = (_min = 0, _max = 1000) => {
    const min = Math.ceil(_min);
    const max = Math.floor(_max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const dateToString = (_date, getDate, getTime) => {
    let date = _date;

    const dayStr = () => {
        let day = date.getDate();
        day = day < 10 ? '0' + day : '' + day;
        let month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : '' + month;
        let year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const timeStr = () => {
        let hours = date.getHours();
        hours = hours < 10 ? '0' + hours : '' + hours;
        let minutes = date.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : '' + minutes;

        return `${hours}:${minutes}`;
    };

    if(!date) {
        return '';
    }

    if(typeof(date) === 'number' || typeof(date) === 'string') {
        date = new Date(date);
    }

    if(getDate && getTime) {
        return `${dayStr()} ${timeStr()}`;
    }
    if(getDate) {
        return dayStr();
    }
    if(getTime) {
        return timeStr();
    }
};

export const dateDiff = (date1, date2) => {
    try {
        var diff = (new Date(date2)) - (new Date(date1)); 
        return isNaN(diff) ? NaN : {
            diff : diff,
            ms : Math.floor( diff            % 1000 ),
            s  : Math.floor( diff /     1000 %   60 ),
            m  : Math.floor( diff /    60000 %   60 ),
            h  : Math.floor( diff /  3600000 %   24 ),
            d  : Math.floor( diff / 86400000        )
        };
    }
    catch(ex) {
        return NaN;
    }
};

export const stringScoreNormalizer = (_str) => {
    let str = _str.replace(/[\u0591-\u05C7]/g, '');                                     // remove Hebrew score from string
    str = str.replace(/[\u0610-\u061A\u0640\u064B-\u065F\u0670\u06D6-\u06ED]/gi, '');   // remove Arabic score from string

    return str;
};

export const calcJewishYear = (civilYear) => {
    civilYear = Number(civilYear) - 1240;
    const letters = {
      1: 'א',
      2: 'ב',
      3: 'ג',
      4: 'ד',
      5: 'ה',
      6: 'ו',
      7: 'ז',
      8: 'ח',
      9: 'ט',
      10: 'י',
      20: 'כ',
      30: 'ל',
      40: 'מ',
      50: 'נ',
      60: 'ס',
      70: 'ע',
      80: 'פ',
      90: 'צ',
      100: 'ק',
      200: 'ר',
      300: 'ש',
      400: 'ת'
    };
  
    let units, dozens, hundreds, rest;
    units = civilYear % 10;
    civilYear -= units;
    dozens = civilYear % 100;
    civilYear -= dozens;
    hundreds = civilYear % 400;
    civilYear -= hundreds;
    rest = civilYear / 400;
    let result = '';
  
    for (let i = 0; i < rest; i++) {
      if (!hundreds && i === rest - 1) {
        result += '"';
      }
      result += 'ת';
    }
  
    if (hundreds) {
      if (!dozens) {
        result += '"';
      }
      result += letters[hundreds];
    }
  
    if (dozens) {
      if (!units) {
        result += '"';
      }
      result += letters[dozens];
    }
  
    if (units) {
      result += '"';
      result += letters[units];
    }
  
    return result;
};

const studyYearData = {
    startDay: 1,
    startMonth: 8,
    endDay: 31,
    endMonth: 7,
    semesters: {
        // 2 Semesters Dates
        '2': {
            A: {
                startDay: 1,
                startMonth: 8,
                startYearOffset: 0,
                endDay: 31,
                endMonth: 1,
                endYearOffset: 1
            },
            B: {
                startDay: 1,
                startMonth: 2,
                startYearOffset: 1,
                endDay: 31,
                endMonth: 7,
                endYearOffset: 1
            }
        },
        // 3 Semesters Dates
        '3': {
            A: {
                startDay: 1,
                startMonth: 8,
                startYearOffset: 0,
                endDay: 21,
                endMonth: 12,
                endYearOffset: 0
            },
            B: {
                startDay: 8,
                startMonth: 1,
                startYearOffset: 1,
                endDay: 1,
                endMonth: 4,
                endYearOffset: 1
            },
            C: {
                startDay: 18,
                startMonth: 4,
                startYearOffset: 1,
                endDay: 31,
                endMonth: 7,
                endYearOffset: 1
            }
        }
    }
};

export const getStudyYearData = (startYear, numOfSemesters = 2) => {
    const studyYearSemesters = {};

    Object.entries(studyYearData.semesters[numOfSemesters]).forEach(([semesterKey, semester]) => {
        studyYearSemesters[semesterKey] = {
            start: new Date(startYear + semester.startYearOffset, semester.startMonth - 1, semester.startDay, 0, 0, 0),
            end: new Date(startYear + semester.endYearOffset, semester.endMonth - 1, semester.endDay, 23, 59, 59)
        };
    });

    return {
        startYear,
        endYear: startYear + 1,
        title: `${startYear}/${startYear+1}`,
        jewishTitle: calcJewishYear(startYear + 1),
        start: new Date(startYear, studyYearData.startMonth - 1, studyYearData.startDay, 0, 0, 0),
        end: new Date(startYear + 1, studyYearData.endMonth - 1, studyYearData.endDay, 23, 59, 59),
        semesters: studyYearSemesters
    }
};

export const getStudyYearDataByDate = (_date, numOfSemesters = 2) => {
    let date = _date ? new Date(_date) : new Date();
    let startYear = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const startYearStr = `${studyYearData.startMonth < 10 ? '0' : ''}${studyYearData.startMonth}/${studyYearData.startDay < 10 ? '0' : ''}${studyYearData.startDay}`;
    const theDateStr = `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}`;
    if(startYearStr > theDateStr) {
        startYear--;
    }

    return getStudyYearData(startYear, numOfSemesters);
};