import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      // payload.gradeId = 'grade1';
      return payload ? { ...payload } : null;
    },
    setProfileAvatar: (state, { payload }) => {
      state.thumbnailId = payload;
    },
    updateUserIsExtendedCatalog: (state, { payload }) => {
      return {
        ...state,
        isExtendedCatalog: payload.isExtendedCatalog
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const { setProfile, setProfileAvatar, updateUserIsExtendedCatalog  } = profileSlice.actions;

export default profileSlice.reducer;
