import { useEffect, useState } from 'react';
import { callWithCache } from '../utils/callWithCache';

let cachedResponses;

export const useFilterItemTypes = () => {
  const [filterItemTypes, setFilterItemTypes] = useState({});

  useEffect(() => {
    if (!cachedResponses) {
      callWithCache(window.cet.microservices.nairobiapi.filters.getFiltersItemTypes, 'getFiltersItemTypes', null).then(response => {
        // Filter out 'evaluation' from the array. This itemType is not relevant for students.
        for (let key in response) {
          response[key] = response[key].filter(item => item !== 'evaluation');
        }
        cachedResponses = response;
        setFilterItemTypes(response);
      });
    } else {
      setFilterItemTypes(cachedResponses);
    }
  }, []);

  return filterItemTypes;
};
