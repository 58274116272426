import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemLanguages: ['he', 'ar'],
  schoolStartDate: null,
  schoolEndDate: null,
  labels: null,
  allDisciplines: null
};

//This is a fix to api bug handling the Date object.
const toFullDateTime = date => {
  date = new Date(date);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setSchoolYearRange: (state, { payload }) => {
      state.schoolStartDate = toFullDateTime(payload.start);
      state.schoolEndDate = toFullDateTime(payload.end);
    },
    setLabels: (state, { payload }) => {
      state.labels = payload ? { ...payload } : null;
    },
    setAllDisciplines: (state, { payload }) => {
      state.allDisciplines = payload ? { ...payload } : null;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSchoolYearRange, setLabels, setAllDisciplines } = configurationsSlice.actions;

export default configurationsSlice.reducer;
