import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  snackbarMessage: ''
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSnackbarMessage: (state, { payload }) => {
      state.snackbarMessage = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setIsLoading, setSnackbarMessage } = uiSlice.actions;

export default uiSlice.reducer;
