import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const useDimensionsForSelect = (props, addAllOption = true) => {
  const intl = useIntl();

  const { dimensions, dimensionsMapper, dependencies, dependenciesMapper } = useSelector(({ dimensions }) => dimensions);

  if (typeof props === 'string') {
    props = [props];
  }

  return useMemo(() => {
    const result = {};
    if (dimensions) {
      result.dependencies = dependencies;
      result.dependenciesMapper = dependenciesMapper;
      result.dimensionsMapper = dimensionsMapper;
      result.ageGradesMapper = dimensionsMapper.ageGrades;
      props.forEach((prop) => {
        result[prop] = dimensions[prop]
          ? dimensions[prop].map(({ id, name, parentId }) => ({ value: id, text: name, label: name, parentId, prop }))
          : [];
        if (addAllOption) {
          const name = intl.formatMessage({ id: 'all' });
          result[prop].unshift({ value: 'all', text: name, label: name, prop });
        }
      });
    }

    return result;
  }, [addAllOption, dependencies, dependenciesMapper, dimensions, dimensionsMapper, intl, props]);
};
