import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { Site } from 'cet-components-lib/dist/Content/Site';
import { Header } from 'cet-components-lib/dist/Content/Site/Header';
import { SideBar } from 'cet-components-lib/dist/Content/Site/SideBar';
import { Footer } from 'cet-components-lib/dist/Content/Site/Footer';
import { Page } from 'cet-components-lib/dist/Content/Site/Page';
import { Login } from 'cet-components-lib/dist/Content/Login';
import { MissingUserInfo } from 'cet-components-lib/dist/Content/MissingUserInfo';
import MobileLandscapeView from '../../components/MobileLandscapeView';

import { CetComponentsProvider } from 'cet-components-lib/dist/providers/CetComponentsProvider';

import { imagesService } from '../../services/images';
import { useSideBar } from './hooks/useSideBar';
import { useProfile, usePagesLearningEvents } from '../../hooks';
import { pageKeys, usePages } from '../../hooks/usePages';
import { useDeviceData, useSessionData } from 'cet-components-lib/dist/hooks';
import { getEnvDomain } from '../../utils/urls';
import { UiProvider } from '../../contexts';

const AppSite = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const sessionData = useSessionData();
  const navigate = useNavigate();
  const { updateUiLanguage } = useProfile();
  usePagesLearningEvents();
  const { pathname } = useLocation();
  const { isMobileView, isIOS, isAndroid} = useDeviceData();

  usePages(pageKeys[pathname] ?? pageKeys.home);

  const { studentId, studentGrade, avatarUrl } = useSelector(state => ({
    studentId: state.profile?.studentId,
    studentGrade: state.profile?.gradeId.split('grade')[1],
    avatarUrl: state.profile?.thumbnailId ? imagesService.getAvatarUrl(state.profile.thumbnailId) : null
  }));

  const sideBarItems = useSideBar();

  const handleLanguageChange = async ( value ) => {
    updateUiLanguage(value);
  };

  const handleShowProfile = () => navigate(`/${intl.locale}/my-profile`);

  const handleLoginClose = () => {
    window.location.replace(`https://myofek${getEnvDomain()}cet.ac.il/${intl.locale}`);
  }

  return (
    <div style={{ direction: 'rtl' }}
         dir="rtl"
         lang={intl.locale}
         data-age-group={studentGrade < 7 ? 'elementary' : 'high'}
         data-is-mobile-view={Number(isMobileView)}
         data-is-android={Number(isAndroid)}
         data-is-ios={Number(isIOS)}
    >
      <CetComponentsProvider value={{ language: intl.locale, siteKey: 'myOfek' }}>
        <UiProvider>
          <Site
            header={
              <Header
                lang={intl.locale}
                langs={['he', 'ar']}
                onLanguageChange={handleLanguageChange}
                isShowLogin={true}
                onShowProfileClick={handleShowProfile}
                avatarUrl={avatarUrl}
                avatarSize="medium"
                // purchaseButtonGuestLabel={formatMessage({ id: 'purchase_button_guest_label_students' })}
                // purchaseButtonGuestHref={formatMessage({ id: 'purchase_button_guest_href_students' })}
                // purchaseButtonLabel={formatMessage({ id: 'purchase_button_label_students' })}
                // purchaseButtonHref={formatMessage({ id: 'purchase_button_href_students' })}
                supportButtonLabel={!studentId ? '' : formatMessage({ id: 'support_button_label_students' })}
                supportButtonHref={!studentId ? '' : formatMessage({ id: 'support_button_href_students' })}
              />}
            sideBar={<SideBar items={sideBarItems} />}
            footer={<Footer/>}
          >
            <Page>
              {sessionData?.sessionId ? (
                (!studentId || !studentGrade)
                  ? <MissingUserInfo />
                  : <Outlet />
              ) : (
                sessionData.isSessionDataReady && <Login
                  lang={intl.locale}
                  hasCloseBtn={true}
                  onClose={handleLoginClose}
                />
              )}
            </Page>

            <MobileLandscapeView />
          </Site>
        </UiProvider>
      </CetComponentsProvider>
    </div>
  );
};

AppSite.propTypes = {};

export default AppSite;
