// import { systemLanguages } from '../config';
export const isHomePage = (locale) => {
  return  !!(window.location.pathname.match(new RegExp(`^\/${locale}\/?$`), 'i'));
}

export const getEnvDomain = (usingQaDomain = true) => {
  const host = window.location.hostname.toLowerCase();
  let envDomain = '.';
  if (host.includes('.testing.')) {
    envDomain = '.testing.';
    if (usingQaDomain && host.includes('-')) {
      envDomain = '-' + host.split('-')[1].replace('cet.ac.il', '');
    }
  }
  else if (host.includes('.dev.') || host.includes('localhost')) {
    envDomain = '.dev.';
  }
  return envDomain;
};

export const isFullUrl = (url) => {
  const pattern = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (pattern.test(url))
    return true;
  return false;
}

// export const getInnerUrl = (url) => {
//   let innerUrl = false;
//   if(url && url.substring(0, 11) === "javascript:") {
//     return url;
//   }
//   if (url) {
//     let linkTag = window.document.createElement('a');
//     linkTag.href = url;
//     let localHost = window.location.hostname.toLowerCase();
//     let linkHost = linkTag.hostname.toLowerCase();
//     if (localHost === linkHost) {
//       let lang = '';
//       let localPathname = window.location.pathname.toLowerCase();
//       for (let i = 0; i < systemLanguages.length; i++) {
//         if (localPathname === `/${systemLanguages[i]}` || localPathname.indexOf(`/${systemLanguages[i]}/`) === 0) {
//           lang = systemLanguages[i];
//           i = systemLanguages.length;
//         }
//       }

//       let relativeLink = linkTag.pathname;
//       relativeLink = relativeLink
//         .replace(new RegExp('^(' + systemLanguages.map((l) => `(\/${l}\/)`).join('|') + ')', 'i'), '/')
//         .replace(new RegExp('^(' + systemLanguages.map((l) => `(\/${l})`).join('|') + '$)', 'i'), '/');
//       if (lang) {
//         relativeLink = `${lang}${relativeLink}`
//       }
//       innerUrl = relativeLink + linkTag.search;

//     } else {
//       innerUrl = linkTag.href;
//     }
//   }
//   return innerUrl;
// };

export const getQueryStringParams = (searchParams) => {
  const { search } = window.location;
  const searchStr = searchParams || search;
  if (searchStr) {
    const queryParams = {};
    const urlSearchParams = new URLSearchParams(searchStr);
    urlSearchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }

  return {};
};

export const setUrlParams = (params) => {
  const { pathname } = window.location;

  const search =
    '?' +
    Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return `${key}=${params[key].join(',')}`;
        } else {
          return `${key}=${params[key]}`;
        }
      })
      .join('&');

  return {
    pathname,
    search,
  };
};

/**
 * Same as "setUrlParams" with removal of unset keys
 * @param {object} params
 * @returns {{search: string, pathname: any | string}}
 */
export const setAllExistingUrlParams = (params) => {
  Object.keys(params).forEach(key => {
    if (params[key] === null || params[key] === undefined) {
      delete params[key];
    }
  });
  return setUrlParams(params);
}

export const getPathnameParam = (paramName) => {
  let paramVal = null;
  let match = window.location.pathname.match(new RegExp(`/${paramName}/([^/?]*)`, 'i'));
  if (match) {
    paramVal = match[1];
  }
  return paramVal;
};

export const setPathnameParam = (paramName, paramVal) => {
  let pathname = '';
  let search = window.location.search;
  let match = window.location.pathname.match(new RegExp(`/${paramName}/[^/?]*`, 'i'));

  if (!paramVal) {
    pathname = window.location.pathname.replace(new RegExp(`/${paramName}/[^/?]*`, 'i'), '/').replace(new RegExp('//', 'i'), '/').replace(new RegExp('/$', 'i'), '');
  }
  else if (match) {
    pathname = window.location.pathname.replace(new RegExp(`/${paramName}/[^/?]*`, 'i'), `/${paramName}/${paramVal}`);
  }
  else {
    pathname = `${window.location.pathname.replace(new RegExp('/$', 'i'), '')}/${paramName}/${paramVal}`;
  }

  return {
    pathname,
    search
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const nairobiGateway = `https://nairobigateway${getEnvDomain()}cet.ac.il`;
//export const apiGatewayUrl = `https://apigateway${getEnvDomain()}cet.ac.il`;
export const productPlayer = `//productplayer${getEnvDomain()}cet.ac.il`;
export const lo = `//lo${getEnvDomain(false)}cet.ac.il`;
export const mybagui = `//mybagui${getEnvDomain(false)}cet.ac.il`;
export const accounts = `//accounts${getEnvDomain(false)}cet.ac.il`;
export const storage = `//nairobitestingstorage.blob.core.windows.net`;
export const avatarStorage = `${storage}/usersuploads/avatrs/`;
export const thumbnailsStorage = `${storage}/thumbnails/`;
export const kotarSchoolUrl = (lang = 'he') => `//${lang !== 'he' ? lang + '.' : ''}school.kotar${getEnvDomain(false)}cet.ac.il/KotarApp/BrowseBooks.aspx`;
export const kotarShopUrl = `//school.kotar${getEnvDomain(false)}cet.ac.il/KotarApp/Shop/AddToCart.aspx`;
export const ofakimApiUrl = `//ebag${getEnvDomain(false)}cet.ac.il/api`;
export const environment = `//environment${getEnvDomain(false)}cet.ac.il/`;
export const mybagApi = `//mybagapi${getEnvDomain(false)}cet.ac.il`;
