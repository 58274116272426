import React from 'react';
import { useIntl } from 'react-intl';

import { Title } from 'cet-components-lib/dist/UI/Title';
import Button from '../../legacyComponents/Ui/Button';
import image from './404.png';
import styles from './NotFound.module.scss';

const NotFound = ({ hasErrorBoundary }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className={styles.container}>
      <Title level={1}>{hasErrorBoundary ? formatMessage({ id: 'not_found_error_title' }) : formatMessage({ id: 'not_found_title' })}</Title>
      <p>{formatMessage({ id: 'not_found_content' })}</p>
      <Button className={styles.button} size="x-large" view="contained" color="primary" to={`/${intl.locale}`}>
        {formatMessage({ id: 'back_to_home_page' })}
      </Button>
      <img src={image} alt="" className={styles.image} />
    </div>
  );
};

export default NotFound;
