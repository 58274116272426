import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSearch, useFilterItemTypes } from '../hooks';

import { getQueryStringParams, setUrlParams } from '../utils/urls';
import { parseSearchValue, setSearchBy } from '../utils/search';
import { setListItems, setDimensions } from '../state/items';
import { callWithCache } from '../utils/callWithCache';
import { isObjectEmpty } from '../utils/objects';

export const useLists = isCatalog => {
  const dispatchSearch = useSearch();
  const filterItemTypes = useFilterItemTypes();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  const isReady = useRef(false);
  const prevPathname = useRef('');
  const isFiltered = useRef(false);
  const [wasSearchCalled, setWasSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isAdvancedFiltersEnabled, setIsAdvancedFiltersEnabled] = useState(false);
  const [disciplinesAndAgeGrades, setDisciplinesAndAgeGrades] = useState(null);
  const [ageGradesAndDisciplines, setAgeGradesAndDisciplines] = useState(null);

  const filters = getQueryStringParams();

  const { items, sector, allDimensionsItemTypes } = useSelector(({items, profile, dimensions }) => {
    return {
      items: items?.listItems,
      sector: profile?.schoolSectorId,
      allDimensionsItemTypes: dimensions.dimensions.itemTypes
    };
  });


  const mapRecordsDimensions = (records) => records
    .filter(record => record.stats)
    .map(({ itemTypes, title, totalCount }) => ({
      value: itemTypes,
      text: title,
      totalCount
    }));

  const fetchDimensions = useCallback(
    (records) => {

      const recordsDimensions = mapRecordsDimensions(records);
      dispatch(setDimensions(recordsDimensions))

    },
    [search]
  );

  const getRecords = useCallback(
    async () => {
      const itemTypes = filters?.itemTypes;
      let searchBy = setSearchBy(filters);
      let statsType = 2;

      if (!itemTypes || itemTypes === 'all') {

        const catalogItemTypes = allDimensionsItemTypes.map(item => item.id).filter(item => item !== 'book' && item !== 'course');

        const itemTypesIndex = searchBy.findIndex(i => i.itemTypes);
        if (itemTypesIndex >= 0) {
          searchBy[itemTypesIndex].itemTypes = catalogItemTypes;
        } else {
          searchBy.push({itemTypes: catalogItemTypes});
        }

      }

      isFiltered.current =
        Object.keys(filters).filter(key => key !== 'allFields' && key !== 'ageGrades' && key !== 'disciplines' && key !== 'itemTypes' && key !== 'exactSearch').length > 0;

      const maxResults =
        filters.hasOwnProperty('allFields') || filters.hasOwnProperty('itemTypes') || filters.hasOwnProperty('ageGrades') || filters.hasOwnProperty('disciplines')
          ? 200 //maxResultsItems
          : 10;

      const records = await dispatchSearch(searchBy, 'itemTypes', maxResults, null,true, 'filter', true, statsType);

      return records;
    },
    [search, dispatchSearch]
  );

  const fetchSearch = useCallback(
    async () => {
      const isFetchDimensions = !filters.hasOwnProperty('itemTypes') || filters.itemTypes === 'all';

      setWasSearchCalled(false);
      setIsLoading(true);

      if (
        filters.allFields &&
        (filters.exactSearch === 1 || filters.exactSearch === '1') &&
        !(filters.allFields[0] === '"' && filters.allFields[filters.allFields.length - 1] === '"')
      ) {
        filters.allFields = `"${filters.allFields}"`;
      }

      if(!isNaN(filters.allFields)) {
        filters.allFields = filters.allFields.toString();
      }

      let records;
      let dimensions = []

      records = await getRecords();
      dimensions = records.filter(record => record.stats);
      records = records.filter(record => record?.data?.length > 0);

      dispatch(setListItems(records));
      isFetchDimensions && dimensions && fetchDimensions(dimensions);

      if (!isObjectEmpty(filters)) {
        setWasSearchCalled(true);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    },
    [search]
  );

  const onItemTypeChange = useCallback(
    (prevFilters, value) => {
      const filters = { ...prevFilters, itemTypes: value };

      setIsFiltersOpen(false);

      if (value === 'all') {
        delete filters.itemTypes;
      }

      const basicFilters = ['ageGrades', 'disciplines', 'allFields', 'itemTypes', 'exactSearch', 'teachersCenter', 'syllabusSubjects', 'syllabusSubsubjects'];
      const filtersToRemove = Object.keys(filters).filter(key => !basicFilters.includes(key));

      filtersToRemove.forEach(key => {
        delete filters[key];
      });

      if (prevFilters.itemTypes !== filters.itemTypes) {
        const historyState = setUrlParams(filters);
        navigate(historyState);
      }
    },
    [navigate]
  );

  const loadDisciplinesAndAgeGrades = useCallback(async sector => {
    var result = null;
    await callWithCache(window.cet.microservices.contentapi.content.getDisciplinesByAgeGrades, 'getDisciplinesByAgeGrades', [sector] || [])
      .then(data => {
        result = data;
      })
      .catch(error => {
        console.error(error);
      });

    return result;
  }, []);

  useEffect(() => {
    let isActive = true;

    async function fetchData(sector) {
      let response = await loadDisciplinesAndAgeGrades(sector);
      if (isActive && response) {
        setDisciplinesAndAgeGrades(response);
        const inverse = {};
        Object.entries(response).map(item => item[1].map(discipline => {
          if (!inverse[discipline.id]) {
            inverse[discipline.id] = [];
          }
          inverse[discipline.id].push(item[0]);
        }))
        setAgeGradesAndDisciplines(inverse);
      }
    }

    setTimeout(() => {
      if (isActive) {
        fetchData(sector);
      }
    }, 10);

    return () => {
      isActive = false;
    };
  }, [loadDisciplinesAndAgeGrades, sector]);

  const onFilterChange = useCallback(
    (prevFilters, filters) => {
      const keys = Object.keys(filters);
      const needRefresh =
        keys.find(key => {
          if (filters[key] !== prevFilters[key]) {
            if ((prevFilters[key] === 'all' && !filters[key]) || (!prevFilters[key] && filters[key] === 'all')) {
              return false;
            }

            return true;
          }

          return false;
        }) || keys.length !== Object.keys(prevFilters).length;

      if (needRefresh) {
        const historyState = setUrlParams(filters);

        navigate(historyState);
      }
    },
    [navigate]
  );

  const onToggleFilters = () => {
    setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  };

  // scroll to top
  useEffect(() => {
    document.querySelector('#root')?.scrollTo(0, 0);
    setWasSearchCalled(false);
  }, [search]);

  // setIsAdvancedFiltersEnabled
  useEffect(() => {
    const filterItemTypesKeys = Object.keys(filterItemTypes);
    if (filterItemTypesKeys.length > 0) {
      if (!filters?.itemTypes) {
        setIsAdvancedFiltersEnabled(false);
      } else {
        const filter = filterItemTypesKeys.find(key => {
          return filterItemTypes[key]?.includes(filters?.itemTypes);
        });

        setIsAdvancedFiltersEnabled(!!filter);
      }
    }
  }, [filters?.itemTypes, filterItemTypes, dispatch]);

  // loadBasicSwimlanes
  useEffect(() => {
    if (isReady.current) {
      const queryParams = getQueryStringParams();
      const parsedParams = {};
      Object.keys(queryParams).forEach(key => (parsedParams[key] = parseSearchValue(queryParams[key])));

      if (isCatalog && (!queryParams.ageGrades || !queryParams.disciplines)) {
        // loadBasicSwimlanes();
        setIsLoading(false);
      }
    }
  }, [isCatalog]);

  // fetchSearch
  useEffect(() => {
    if (search && !wasSearchCalled) {

      fetchSearch();
    }
  }, [ pathname, search, wasSearchCalled]);

  //This will work as long is this this the lat useEffect
  useEffect(() => {
    prevPathname.current = pathname;
  }, [pathname]);

  return {
    onItemTypeChange,
    onFilterChange,
    onToggleFilters,
    items: items.find(tab => tab.itemTypes === filters.itemTypes),
    filters,
    isAdvancedFiltersEnabled,
    isFiltersOpen,
    isLoading,
    isFiltered: isFiltered.current,
    wasSearchCalled,
    disciplinesAndAgeGrades,
    ageGradesAndDisciplines
  };
};
