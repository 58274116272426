import { configureStore } from '@reduxjs/toolkit';
// SLICES
import dimensions from './dimensions';
import activities  from "./activities";
import configurations from "./configurations";
import ui from "./ui";
import profile from "./profile";
import items from "./items";

export const store = configureStore({
  reducer: {
    // REDUCERS
    activities,
    configurations,
    ui,
    profile,
    items,
    dimensions,
  },
});
