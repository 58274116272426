export const fileService = {
  uploadBlob: async (blob, container, prefix) => {
    const file = new window.File([blob], blob.name, { type: blob.type });

    try {
      const url = await window.cet.microservices.nairobiapi.fileUpload.upload({
        container,
        prefix,
        file
      });

      return url;
    } catch (error) {
      return '';
    }
  }
};
