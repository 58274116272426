import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionData, useDeviceData } from 'cet-components-lib/dist/hooks';
import { profileService } from '../services/profile';
import { configurationsService } from '../services/configurations';
import { setIsLoading } from '../state/ui';
import { setProfile } from '../state/profile';
import { initDimensions } from '../state/dimensions';
import { setSchoolYearRange, setLabels, setAllDisciplines } from '../state/configurations';
import { CacheProvider } from '../utils/cacheProvider';

export const useInitApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useDeviceData();
  const { language } = useParams();
  let { pathname } = useLocation();
  const sessionData = useSessionData();

  const [isError, setIsError] = useState(false);

  const { labels, systemLanguages, isLoading, profile } = useSelector(state => ({
    labels: state.configurations.labels,
    profile: state.profile,
    systemLanguages: state.configurations.systemLanguages,
    isLoading: state.ui.isLoading
  }));

  useEffect(() => {
    const uiLanguage = profile?.uiLanguage;
    if (uiLanguage !== language && systemLanguages.includes(uiLanguage)) {
      window.location.replace(window.location.href.replace(language, uiLanguage));
    }
  }, [language, profile?.uiLanguage, systemLanguages]);

  useEffect(() => {
    try {
      if (!language || !systemLanguages.includes(language)) {
        navigate('/he', { replace: true });
      } else if (sessionData.isSessionDataReady) {
        if (sessionData.role) {
          if (sessionData.userId && sessionData.role === 'student') {
            if (!profile) {
              dispatch(setIsLoading(true));
              Promise.all([
                configurationsService.loadLabels([language]),
                configurationsService.loadAllDisciplines(language),
                configurationsService.loadAllDimensions(language),
                configurationsService.loadAllDependencies(),
                profileService.loadStudentProfile(sessionData.userId, sessionData.schoolId),
                configurationsService.loadSchoolYearRange()
              ])
                .then(([i18nLabels, disciplinesByAgeGrades, dimensions, dependencies, studentProfile, schoolYearRange]) => {
                  dimensions.ageGrades = dimensions.ageGrades?.sort((ageA, ageB) => ageA.orderBy - ageB.orderBy);
                  const uiLanguage = studentProfile.uiLanguage ?? 'he';

                  dispatch(setLabels(i18nLabels));
                  dispatch(setAllDisciplines(disciplinesByAgeGrades));
                  dispatch(initDimensions({ dimensions, dependencies }));


                  if (studentProfile) {
                    if (uiLanguage !== language && systemLanguages.includes(uiLanguage)) {
                      navigate(pathname.toLocaleLowerCase().replace(language, uiLanguage), {
                        replace: true
                      });
                    }

                    studentProfile.studentId = sessionData.userId;
                    console.warn('settings profile.studentId = sessionData.userId !!!');

                    dispatch(setProfile(studentProfile));
                    dispatch(setSchoolYearRange(schoolYearRange));
                  }

                  dispatch(setIsLoading(false));
                })
                .catch(error => {
                  if (error?.detail?.status === 404) {
                    configurationsService
                      .loadLabels([language])
                      .then(i18nLabels => {
                        dispatch(setLabels(i18nLabels));
                        dispatch(setIsLoading(false));
                      })
                      .catch(error => console.error(error));
                  } else {
                    throw new Error(error);
                  }
                });
            }
          } else {
            dispatch(setIsLoading(true));
            window.location.replace(window.location.protocol + '//' + window.location.host.replace('students.', ''));
          }
          // dispatch(setIsLoading(true));
          // Promise.all([
          //   configurationsService.loadLabels(systemLanguages),
          //   configurationsService.loadAllDisciplines(language),
          //   profileService.loadStudentProfile(sessionData.userId, sessionData.schoolId),
          //   configurationsService.loadSchoolYearRange()
          // ])
          //   .then(([i18nLabels, allDisciplines, profile, schoolYearRange]) => {
          //     const uiLanguage = profile.uiLanguage ?? 'he';
          //     dispatch(setLabels(i18nLabels));
          //     dispatch(setAllDisciplines(allDisciplines));
          //     if (profile) {
          //       if (uiLanguage !== language && systemLanguages.includes(uiLanguage)) {
          //         navigate(pathname.toLocaleLowerCase().replace(language, uiLanguage), {
          //           replace: true
          //         });
          //       }
          //       profile.studentId = sessionData.userId;
          //       console.warn('settings profile.studentId = sessionData.userId !!!');
          //       dispatch(setProfile(profile));
          //       dispatch(setSchoolYearRange(schoolYearRange));
          //     }
          //     dispatch(setIsLoading(false));
          //   })
          //   .catch(error => {
          //     if (error?.detail?.status === 404) {
          //       configurationsService
          //         .loadLabels(systemLanguages)
          //         .then(i18nLabels => {
          //           dispatch(setLabels(i18nLabels));
          //           dispatch(setIsLoading(false));
          //         })
          //         .catch(error => console.error(error));
          //     } else {
          //       throw new Error(error);
          //     }
          //   });
        } else {
          CacheProvider.clear();
          dispatch(setLabels({}));
          dispatch(setIsLoading(false));
        }
      }
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  }, [dispatch, language, navigate, pathname, profile, sessionData.isSessionDataReady, sessionData.role, sessionData.schoolId, sessionData.userId, systemLanguages]);

  useEffect(() => {
    try {
      if (window.interdeal && window.interdeal.setPosition) {
        window.interdeal.setPosition((language === 'he' || language === 'ar') && !isMobileView ? 'Right' : 'Left');
        window.interdeal.setLanguage(language);
      }
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  }, [isMobileView, language]);

  useEffect(() => {
    document.addEventListener('cet.accessmanagement.logout', CacheProvider.clear);

    return () => {
      document.removeEventListener('cet.accessmanagement.logout', CacheProvider.clear);
    };
  });

  return {
    labels,
    isLoading,
    isError
  };
};
