import { callWithCache } from '../utils/callWithCache';

export const coursesService = {
  search: async (externalContextId, disciplines, language = 'he', maxResults = 30, sectors = ['state', 'mmad', 'arab']) => {
    if (!Array.isArray(externalContextId)) {
      externalContextId = [externalContextId];
    }

    if (!Array.isArray(sectors)) {
      sectors = [sectors];
    }

    const searchParameters = {
      lang: language,
      skipCache: true,
      orderBy: null,
      dimensionParams: { sectors, itemTypes: ['course'] },
      contentParams: { isPublished: [true], externalContextId },
      textParams: {},
      retrieveParams: { dimensionName: 'disciplines', maxResults }
    };

    if (disciplines) {
      searchParameters.dimensionParams.disciplines = Array.isArray(disciplines) ? disciplines : [disciplines];
    }

    const searchResults = await callWithCache(window.cet.microservices.contentapi.search.search, 'courses', searchParameters);

    return searchResults;
  }
};
