import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './utils/optimized-events-creator';
import { store } from './state';
import ErrorBoundary from './components/ErrorBoundary';
import { lazyWithRetry } from './utils/lazyWithRetry';

import App from './containers/App';

import reportWebVitals from './reportWebVitals';

import './index.css';

const Tasks = React.lazy(() => lazyWithRetry(() => import('./pages/Tasks')));
const Books = React.lazy(() => lazyWithRetry(() => import('./pages/Books')));
const Courses = React.lazy(() => lazyWithRetry(() => import('./pages/Courses')));
const Catalog = React.lazy(() => lazyWithRetry(() => import('./pages/Catalog')));
const Tools = React.lazy(() => lazyWithRetry(() => import('./pages/Tools')));
const MyProfile = React.lazy(() => lazyWithRetry(() => import('./pages/MyProfile')));
const NotFound = React.lazy(() => lazyWithRetry(() => import('./pages/NotFound')));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <React.Suspense fallback="">
    <Provider store={store}>
      <Router>
        <Routes>
          <Route
            index
            element={
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            }
          />
          <Route
            path="/:language"
            element={
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Tasks />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="books"
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Books />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="courses"
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Courses />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="catalog"
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Catalog />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="tools"
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Tools />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="my-profile"
              element={
                <React.Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <MyProfile />
                  </ErrorBoundary>
                </React.Suspense>
              }
            ></Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  </React.Suspense>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
