export const profileService = {
  loadStudentProfile: async (studentId, schoolId) => window.cet.microservices.studentsapi.profile.getProfile({ studentId, schoolId }),
  updateUiLanguage: async (studentId, schoolId, uiLanguage) => window.cet.microservices.studentsapi.profile.setUILanguage({ studentId, schoolId, uiLanguage }),
  updateThumbnail: async (studentId, url) => {
    if (url) {
      const thumbnailId = url.split('/').pop();
      await window.cet.microservices.studentsapi.profile.setThumbnail({ studentId, thumbnailId });
      return thumbnailId;
    } else {
      return null;
    }
  },
  logout: (language, sendUiUsageEvent) => {
    const categoryName = 'upperToolbar';
    const labelName = 'logout';
    sendUiUsageEvent({ category: categoryName, label: labelName });

    window.cet.accessmanagement.logout(`${window.location.origin}/${language}`);
  },
  changeSchool: language => {
    window.cet.accessmanagement.changeRole(window.location.href, null, language);
  }
};
