import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

export const useSideBar = () => {
  const { isMobile, isMobileView } = useDeviceData();
  const intl = useIntl();
  const { pathname } = useLocation();
  const messagesLength = Object.keys(intl.messages).length;
  const navigate = useNavigate();

  const handleClick = ( e, url ) => {
    e.preventDefault();
    navigate(`/${intl.locale}/${url}`);
  }

  const getSideBarItems = useCallback(() => {
    const toReturn = [
      {
        id: 'activities',
        onClick:  (e) => handleClick(e,''),
        icon: "Activities",
        label: intl.formatMessage({ id: 'tasks_status_student' }),
        isSelected: pathname === `/${intl.locale}`
      },
      {
        id: 'catalog',
        onClick:  (e) => handleClick(e,'catalog'),
        icon: "Flower",
        label: intl.formatMessage({ id: 'catalog' }),
        isSelected: pathname === `/${intl.locale}/catalog`
      },
      {
        id: 'books',
        onClick:  (e) => handleClick(e,'books'),
        icon: "Book",
        label: intl.formatMessage({ id: 'books' }),
        isSelected: pathname === `/${intl.locale}/books`
      },
      {
        id: 'courses',
        onClick:  (e) => handleClick(e,'courses'),
        icon: "Courses",
        label: intl.formatMessage({ id: 'courses' }),
        isSelected: pathname === `/${intl.locale}/courses`
      },
    ];
    if (!isMobileView && !isMobile) {
      toReturn.push({
        id: 'tools',
        onClick:  (e) => handleClick(e,'tools'),
        icon: "Studio",
        label: intl.formatMessage({ id: 'tools_title_students' }),
        isSelected: pathname === `/${intl.locale}/tools`
      });
    }
    return toReturn;
  },[intl, isMobile, isMobileView, pathname]);

  const sideBarItems = useMemo(
    () => (messagesLength > 0
      ? getSideBarItems()
      : []
    ),
    [messagesLength, getSideBarItems]
  );

  return sideBarItems;
};
