import { callNumberOfTries } from '../utils/callNumberOfTries';

const MAX_NUMBER_OF_TRIES = 3;

export const tasksService = {
  loadTasks: async ({ studentId, schoolId, minDate, maxDate, includeQuestions }) =>
    await callNumberOfTries(
      window.cet.microservices.dashboardapi.students.getTasks,
      {
        studentId,
        schoolId,
        minDate,
        maxDate,
        includeQuestions
      },
      MAX_NUMBER_OF_TRIES
    )
};
