import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import loader from './loader.json';

import './Preloader.scss';

const Preloader = ({ className, type = 'inline', url, width, height, top, left }) => {
  const preloaderCss = classNames({
    'cc-Preloader': true,
    ['cc-Preloader--' + [type]]: type,
    [className]: className
  });

  return (
    <div className={preloaderCss} style={{ width, height, top, left }}>
      <Player autoplay={true} loop={true} controls={false} src={url ?? loader}></Player>
    </div>
  );
};

Preloader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['inline', 'fullscreen']),
  url:  PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Preloader;
