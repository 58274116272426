import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  books: null,
  courses: null,
  lastSelectedEnvironments: null
};

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setActivities: (state, { payload }) => {
      state[payload.type] = payload.activities;
    }
  },
  updateCourseLastSelectedEnvironment: (state, { payload }) => {
    state.lastSelectedEnvironments = { ...state.lastSelectedEnvironments, [payload.courseId]: payload.environmentId };
  }
});

// Action creators are generated for each case reducer function
export const { setActivities, updateCourseLastSelectedEnvironment } = activitiesSlice.actions;

export default activitiesSlice.reducer;
