import { CacheProvider } from './cacheProvider';
import { callNumberOfTries } from './callNumberOfTries';

export const callWithCache = async (functionToCall, prefix, params, maxNumberOfTries = 1) => {
  const localCacheKey = CacheProvider.generateKey(prefix, params ?? '');
  const cached = CacheProvider.get(localCacheKey);
  if (cached) {
    // console.log('callWithCache', prefix, cached);
    return cached;
  } else {
    const result = await callNumberOfTries(functionToCall, params, maxNumberOfTries);
    CacheProvider.set(localCacheKey, result);
    return result;
  }
};
