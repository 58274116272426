import React from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useInitApp } from '../../hooks/useInitApp';
import Preloader from '../../legacyComponents/Ui/Preloader';
import AppSite from './AppSite';

import styles from './App.module.scss';

import '../../legacyComponents/Layout/Site/Site.scss';

const App = props => {
  const { language } = useParams();
  const { isLoading, labels, isError } = useInitApp();

  if (isLoading || !labels || isError) {
    return (
      <div className={styles.preloader}>
        <Preloader />
      </div>
    );
  }

  return (
    <IntlProvider locale={language} messages={labels[language]}>
      <AppSite language={language} />
    </IntlProvider>
  );
};

App.propTypes = {};

export default App;
