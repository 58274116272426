import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

const TOTAL_MAX_RESULTS = 8;
const SYLLABUS_MAX_RESULTS = 8;
const TITLE_MAX_RESULTS = 8;

const search = async (intl, searchString, ageGrades, disciplines, allDimensions, lastSchool, isExtendedCatalog) => {
  const requestParams = {
    searchString,
    totalMaxResults: TOTAL_MAX_RESULTS,
    dimensionsParams: {
      sectors: isExtendedCatalog ? ['state', 'mmad', 'arab']
        : (lastSchool?.sectorId ? [lastSchool.sectorId]
          : (intl.locale === 'ar' ? ['arab'] : ['state', 'mmad'])),
      disciplines: [disciplines],
      ageGrades: [ageGrades],
    },
    contentParams: { isPublished: [true] },
    retrieveParams: [
      {
        paramName: 'syllabus',
        numResults: SYLLABUS_MAX_RESULTS,
      },
      {
        paramName: 'title',
        numResults: TITLE_MAX_RESULTS,
      },
    ],
  };

  if (requestParams?.dimensionsParams?.ageGrades) {
    if (ageGrades) {
      requestParams.dimensionsParams.ageGrades = [ageGrades];
    } else {
      delete requestParams.dimensionsParams.ageGrades;
    }
  }

  if (requestParams?.dimensionsParams?.disciplines) {
    if (disciplines) {
      requestParams.dimensionsParams.disciplines = [disciplines];
    } else {
      delete requestParams.dimensionsParams.disciplines;
    }
  }

  const response = await window.cet.microservices.contentapi.search.autocomplete(requestParams);
  if (response?.records) {
    const result = Object.values(response.records)
      .map((records) => records)
      .reduce((acc, current) => {
        return acc.concat(
          current.map((item) => {
            const props = item.dimensions && Object.keys(item.dimensions) ? Object.keys(item.dimensions) : [];
            const dimensions = [];
            props.forEach((prop) => {
              if (prop) {
                const text =
                  allDimensions[prop].find((dimension) => dimension.id === item.dimensions[prop][0])?.name || '';

                if (text) {
                  dimensions.push({ value: item.dimensions[prop][0], text: text, prop });
                }
              }
            });

            return { value: item.text, realValue: item.text, text: item.text, dimensions };
          })
        );
      }, []);

    return result;
  }

  return [];
};
export const useAutoComplete = () => {
  const intl = useIntl();
  const { allDimensions, lastSchool, isExtendedCatalog } = useSelector(({ dimensions, profile }) => {
    return {
      allDimensions: dimensions.dimensions,
      lastSchool: profile.lastSchool,
      isExtendedCatalog: profile.info?.isExtendedCatalog,
    };
  });

  return useCallback(
    async (text, ageGrades, disciplines) => await search(intl, text, ageGrades, disciplines, allDimensions, lastSchool, isExtendedCatalog),
    [allDimensions, intl, lastSchool, isExtendedCatalog]
  );
};
