import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setProfileAvatar, updateUserIsExtendedCatalog } from '../state/profile';
import { fileService } from '../services/file';
import { profileService } from '../services/profile';
import { useCetEvents } from 'cet-components-lib/dist/hooks';

export const useProfile = () => {
  const intl = useIntl();
  const { sendUiUsageEvent } = useCetEvents();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile ?? {});

  const updateProfileAvatar = useCallback(
    async blob => {
      const url = await fileService.uploadBlob(blob, 'usersuploads', 'avatrs');
      const thumbnailId = await profileService.updateThumbnail(profile.studentId, url);

      dispatch(setProfileAvatar(thumbnailId));
    },
    [dispatch, profile.studentId]
  );

  const updateUiLanguage = useCallback(
    async uiLanguage => {
      await profileService.updateUiLanguage(profile.studentId, profile.schoolId, uiLanguage);

      window.location.replace(window.location.href.replace(intl.locale, uiLanguage));
    },
    [intl.locale, profile.schoolId, profile.studentId]
  );

  const logout = useCallback(() => profileService.logout(intl.locale, sendUiUsageEvent), [intl.locale, sendUiUsageEvent]);

  const changeSchool = useCallback(() => profileService.changeSchool(intl.locale), [intl.locale]);

  const updateIsExtendedCatalog = useCallback(
    async updated => {      
      const requestData = {
        studentId: updated.studentId,
        isExtendedCatalog: updated.isExtendedCatalog
      };

      try {        
        dispatch(updateUserIsExtendedCatalog(updated));
        await window.cet.microservices.studentsapi.profile.updateIsExtendedCatalog(requestData);
      } catch (ex) {
        console.error(ex);
      }
    },
    [dispatch] 
  );

  return {
    updateProfileAvatar,
    updateUiLanguage,
    logout,
    changeSchool,
    updateIsExtendedCatalog
  };
};
