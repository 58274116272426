import React from 'react';
import NotFound from '../../pages/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.prevPath = null;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error('info.componentStack', info.componentStack);
  }

  componentDidMount() {
    this.prevPath = window.location.pathname;
  }

  componentDidUpdate() {
    if (window.location.pathname !== this.prevPath) {
      this.setState({ hasError: false });
      this.prevPath = window.location.pathname;
    }
  }

  render() {
    if (this.state.hasError) {
      return <NotFound hasErrorBoundary={this.state.hasError} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
