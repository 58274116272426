import { callWithCache } from '../utils/callWithCache';

export const booksService = {
  search: async (ageGrades, disciplines, language = 'he', maxResults = 10, sectors = ['state', 'mmad', 'arab']) => {
    if (!Array.isArray(ageGrades)) {
      ageGrades = [ageGrades];
    }

    if (!Array.isArray(sectors)) {
      sectors = [sectors];
    }

    const searchParameters = {
      lang: language,
      skipCache: true,
      orderBy: null,
      dimensionParams: { sectors, ageGrades, itemTypes: ['book'], audiences: ['students'] },
      contentParams: { isPublished: [true] },
      textParams: {},
      retrieveParams: { dimensionName: 'disciplines', maxResults }
    };

    if (disciplines) {
      searchParameters.dimensionParams.disciplines = Array.isArray(disciplines) ? disciplines : [disciplines];
    }

    const searchResults = await callWithCache(window.cet.microservices.contentapi.search.search, 'books', searchParameters);

    return searchResults;
  },
  searchByExternalIds: async (language = 'he', externalIds) => {
    const searchParameters = {
      lang: language,
      skipCache: true,
      orderBy: null,
      dimensionParams: { itemTypes: ['book'] },
      contentParams: { externalContextId: externalIds },
      textParams: {},
      retrieveParams: { maxResults: externalIds.length }
    };

    const searchByExternalIdsResults = await callWithCache(window.cet.microservices.contentapi.search.search, 'books_by_external_ids', searchParameters);

    return searchByExternalIdsResults;
  }
};
