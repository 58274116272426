import { callNumberOfTries } from '../utils/callNumberOfTries';

const MAX_NUMBER_OF_TRIES = 3;

export const productSubscriptionsService = {
  loadProductsSubscriptions: async (studentId, getGeneralProducts) =>
    await callNumberOfTries(
      window.cet.microservices.studentsapi.mybag.getProductSubscriptions,
      {
        studentId,
        getGeneralProducts
      },
      MAX_NUMBER_OF_TRIES
    )
};
