const types = {
  initDimensions: 'initDimensions',
  setFilteredDimensions: 'setFilteredDimensions',
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.initDimensions:
      const { dimensions, dependencies } = action;
      const dimensionsMapper = {};
      const dependenciesMapper = {};

      // remove evaluation from itemTypes. it's not relevant for the students.
      let filteredItemTypes = dimensions.itemTypes.filter(item => item.parentId === 'catalog' && item.id !== 'evaluation');
      dimensions.itemTypes = filteredItemTypes;

      Object.keys(dimensions).forEach((key) => {
        dimensions[key].sort((a, b) => (a.orderBy < b.orderBy ? -1 : a.orderBy > b.orderBy ? 1 : 0));
        if (!dimensionsMapper[key]) {
          dimensionsMapper[key] = {};
          dimensions[key].forEach(item => {
            dimensionsMapper[key][item.id] = item;
          });
        }
      });

      Object.keys(dependencies).forEach((key1) => {
        if (!dependenciesMapper[key1]) {
          dependenciesMapper[key1] = {};
        }
        Object.keys(dependencies[key1]).forEach((key2) => {
          if (!dependenciesMapper[key1][key2]) {
            dependenciesMapper[key1][key2] = {};
          }
          if (!dependenciesMapper[key2]) {
            dependenciesMapper[key2] = {};
          }
          if (!dependenciesMapper[key2][key1]) {
            dependenciesMapper[key2][key1] = {};
          }
          dependencies[key1][key2].forEach(item => {
            if (!dependenciesMapper[key1][key2][item.key]) {
              dependenciesMapper[key1][key2][item.key] = {};
            }
            dependenciesMapper[key1][key2][item.key][item.value] = true;

            if (!dependenciesMapper[key2][key1][item.value]) {
              dependenciesMapper[key2][key1][item.value] = {};
            }
            dependenciesMapper[key2][key1][item.value][item.key] = true;
          });
        });
      });

      return { dimensions: dimensions, dimensionsMapper: dimensionsMapper, dependencies: dependencies, dependenciesMapper: dependenciesMapper, filteredDimensions: {} };
    case types.setFilteredDimensions:
      const { filteredDimensions } = action;
      return { ...state, filteredDimensions: filteredDimensions.dimensions };
    default:
      return state;
  }
};

export const initDimensions = ({ dimensions, dependencies }) => ({
  type: types.initDimensions,
  dimensions,
  dependencies,
});
export const setFilteredDimensions = ({ filteredDimensions }) => ({
  type: types.setFilteredDimensions,
  filteredDimensions,
});
export default reducer;
