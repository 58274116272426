export const parseSearchValue = value => {
  return value === 'true' ? true : value === 'false' ? false : isNaN(value) ? value : Number(value);
};

const NON_ARRAY_FILTERS = ['title', 'summary', 'discipline', 'syllabusSubject', 'skill', 'itemType', 'activityType', 'ageGrade', 'allFields'];

export const setSearchBy = filters =>
  Object.keys(filters).map(key => {
    if (Array.isArray(filters[key])) {
      return { [key]: filters[key] };
    } else if (NON_ARRAY_FILTERS.includes(key)) {
      return { [key]: filters[key] };
    } else {
      return { [key]: filters[key].toString().split(',') };
    }
  });

/**
 * Check if the search is just a number instead of text, and no filters were selected
 * @param {Array} searchBy
 * @returns {boolean}
 */
export const isSearchTermNothingButNumber = (searchBy) => {
  const searchKeys = searchBy.map(item => Object.keys(item)[0]);
  return (!searchKeys.includes('ageGrades') && !searchKeys.includes('disciplines') && searchKeys.includes('allFields') && !isNaN(searchBy[0].allFields));
}