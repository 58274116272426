export const isObjectEmpty = (objectName) => {
	return Object.keys(objectName).length === 0
};

export const inverseObject = (obj) => {
	let result = {};

	for (let key in obj) {
		obj[key].forEach(item => {
			if (!result[item]) {
				result[item] = [];
			}
			result[item].push(key);
		});
	}

	return result;
}
/**
 * Accepts a key, or an array of keys, and an object. returns true some of the keys exists in the object.
 * @param {String | Array} keys
 * @param {Object} testObject
 * @returns {Boolean}
 */
export const isObjectHasKeys = (keys, testObject) => {
	if (!keys || !testObject) return false;
	if (typeof keys === 'string') {
		return testObject.hasOwnProperty(keys);
	}
	return keys.some(key => {
		const value = typeof key === 'object' ? key.value : key;
		return Object.keys(testObject).includes(value);
	});
};