import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { activitiesService } from '../services/activities';
import { coursesService } from '../services/courses';
import { dataStructure } from '../utils/data-structure';
import { setActivities } from '../state/activities';
import { productSubscriptionsService } from '../services/productSubscriptions';

export const useCourses = (maxResults, discipline) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [myCourses, setMyCourses] = useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = useIntl();

  const { studentId, schoolId, activities, sectors } = useSelector(state => {
    const profile = state.profile ?? {};

    return {
      studentId: profile.studentId,
      schoolId: profile.schoolId,
      gradeId: profile.gradeId,
      activities: state.activities.courses,
      sectors: state?.profile?.schoolSectorId
    };
  });

  const setMyCoursesByActivities = useCallback(
    async activities => {
      try {
        const externalContextId = activities.map(activity => activity.externalId);

        let productSubscriptions = [];
        try {
          productSubscriptions = await productSubscriptionsService.loadProductsSubscriptions(studentId, true);
        } catch { }

        let externalCoursesList = [
          { name: 'פלתורה', domainProductID: 'C916655E-B7F8-4D15-A7B6-ADD95F4EFA23', externalContextId: '1' },
          {
            name: 'Magnilearn',
            domainProductID: formatMessage({ id: 'Magnilearn_externalContextId' }),
            externalContextId: formatMessage({ id: 'Magnilearn_externalContextId' })
          }
          /*{name:'fullproof', productId: 'CF4466EC-CAC9-4418-BAD1-6A48923E3149', nairobiId: '48BAAF35'}//replace with fullproof data*/
        ];

        externalCoursesList.forEach(item => {
          if (productSubscriptions.find(x => x.domainProductID.toUpperCase() === item.domainProductID.toUpperCase())) {
            externalContextId.push(item.externalContextId);
          }
        });

        const searchResults =
          externalContextId?.length > 0
            ? await coursesService.search(externalContextId, discipline, intl.locale, maxResults, sectors)
            : {
              records: {},
              state: {
                totalEllapsed: 0,
                totalFound: 0
              },
              dimensionFound: {}
            };
        const activitiesObj = dataStructure.convertArrayToObject(activities, 'externalId');

        const myCoursesArray = Object.values(searchResults.records)
          .reduce((reduced, records) => {
            records.forEach(course => {
              const activity = activitiesObj[course.externalContextId];
              course.additionalInfo = activity?.additionalInfo;
            });
            return [...reduced, ...records];
          }, [])
          .filter(
            (course, index, coursesArray) =>
              coursesArray.findLastIndex(instance => {
                return instance.externalContextId === course.externalContextId;
              }) === index
          )
          .sort((a, b) => (a.title < b.title ? -1 : 1));

        setMyCourses(myCoursesArray);
        setIsLoading(false);
      } catch (error) {
        setMyCourses([]);
        setIsErrored(true);
        setIsLoading(false);
      }
    },
    [discipline, intl.locale, maxResults, sectors, studentId]
  );

  useEffect(() => {
    if (studentId && !myCourses) {
      if (!activities) {
        setIsLoading(true);
        activitiesService
          .loadActivities(studentId, 'COURSE', schoolId)
          .then(async result => {
            dispatch(setActivities({ type: 'courses', activities: result }));
          })
          .catch(error => {
            dispatch(setActivities({ type: 'courses', activities: [] }));
            console.error(error);
            setIsLoading(false);
            setIsErrored(true);
          });
      } else {
        setMyCoursesByActivities(activities);
      }
    }
  }, [activities, dispatch, myCourses, schoolId, setMyCoursesByActivities, studentId]);

  return { isLoading, isErrored, myCourses };
};
