import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { pagesService } from '../services/pages';

export const pageKeys = {
  home: 'home',
  books: 'books',
  courses: 'courses',
  myProfile: 'my-profile',
  catalog: 'catalog',
  tools: 'tools',
  notFound: 'not-found'
};

export const usePages = pageKey => {
  const intl = useIntl();

  useEffect(() => {
    pagesService.loadPage(pageKey, intl.locale).then(({ title, description, keyWords, image }) => {
      window.document.title = title;
      [
        { selector: ['name', 'description'], content: description },
        { selector: ['name', 'keywords'], content: keyWords },
        { selector: ['name', 'twitter:card'], content: 'summary' },
        { selector: ['name', 'twitter:title'], content: title },
        { selector: ['name', 'twitter:description'], content: description },
        { selector: ['name', 'twitter:image'], content: image },
        { selector: ['property', 'og:title'], content: title },
        { selector: ['property', 'og:description'], content: description },
        { selector: ['property', 'og:image'], content: image },
        { selector: ['property', 'og:url'], content: window.location.toString() }
      ].forEach(tagData => {
        let metaTag = window.document.head.querySelector(`meta[${tagData.selector[0]}="${tagData.selector[1]}"]`);
        if (metaTag) {
          metaTag.content = tagData.content;
        } else {
          metaTag = document.createElement('meta');
          metaTag.setAttribute(tagData.selector[0], tagData.selector[1]);
          metaTag.content = tagData.content;
          document.head.append(metaTag);
        }
      });
    });
  }, [intl.locale, pageKey]);
};
